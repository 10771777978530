import { useState, useEffect } from 'react';
import { Typography, AccordionDetails, Button } from '@mui/material';
import {
  MdFilterList,
  MdLeaderboard,
  MdApartment,
  MdApi,
  MdLanguage,
} from 'react-icons/md';
import { IconContext } from 'react-icons';
import '../../pages/organisation/OrganisationPage.scss';
import {
  getAllTechnologies,
  getKeywordsFromSector,
} from '../../commons/apis/services/technologies';
import { getAllCountries } from '../../commons/apis/services/expert';
import { getAllOrganizations } from '../../commons/apis/services/map';
import Spinner from '../Spinner';
import { ThemeProvider } from '@mui/material/styles';
import CustomizedHook from '../AutocompleteSearch';
import {
  FilterHeader,
  theme,
  accordionTheme,
  Accordion,
  AccordionSummary,
  FilterAccordionSummary,
} from '../../commons/components-lib';
import { useMediaQuery } from 'react-responsive';
import sectors from '../../commons/sectors';

const TechFilter = ({
  relatedTech,
  onTechChange,
  resetFilter,
  state,
  triggerReset,
  filterTitle,
  icon,
}) => {
  const [techList, setTechList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      // const res = await getAllTechnologies();
      const res = await getKeywordsFromSector(filterTitle);
      setTechList(res);
      setIsLoading(false);
    };
    fetchData();
  }, []);
  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            {icon}
          </IconContext.Provider>
          <Typography fontSize={16}>{filterTitle}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Spinner />
        ) : (
          <CustomizedHook
            options={['All', ...techList.map((tech) => tech.name)]}
            label="Choose technologies"
            relatedTech={relatedTech}
            filterTitle={filterTitle}
            onChange={onTechChange}
            resetFilter={resetFilter}
            triggerReset={triggerReset}
            state={state}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const SectorFilter = ({
  relatedTech,
  onTechChange,
  resetFilter,
  state,
  triggerReset,
  filterTitle,
  icon,
}) => {
  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            {icon}
          </IconContext.Provider>
          <Typography fontSize={16}>{filterTitle}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {sectors.map((sector) => (
          <TechFilter
            relatedTech={relatedTech}
            onTechChange={onTechChange}
            resetFilter={resetFilter}
            state={state?.relatedTech}
            triggerReset={triggerReset}
            filterTitle={sector.title}
            icon={sector.icon}
            key={sector.title}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const HIndexFilter = ({
  onHIndexesChange,
  hIndexes,
  resetFilter,
  state,
  triggerReset,
}) => {
  const hIndexFilter = [
    { label: '< 10', value: '1-9' },
    { label: '10 - 25', value: '10-25' },
    { label: '26 - 50', value: '26-50' },
    { label: '51 - 75', value: '51-75' },
    { label: '76 - 100', value: '76-100' },
    { label: '> 100', value: '100-max' },
  ];

  useEffect(() => {
    // Not sure why need to directly update on the state value, use onExpertChange doesn't trigger the changes
    hIndexes.splice(0, hIndexes.length);
    onHIndexesChange([]);
  }, [resetFilter]);

  const handleChange = (selectedLabels) => {
    const selectedObj = hIndexFilter.filter((o) =>
      selectedLabels.includes(o.label),
    );
    onHIndexesChange(selectedObj.map((o) => o.value));
  };

  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            <MdLeaderboard />
          </IconContext.Provider>
          <Typography fontSize={16} noWrap={true}>
            H-Index
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomizedHook
          options={hIndexFilter.map((e) => e.label)}
          label="Choose ranges"
          onChange={handleChange}
          resetFilter={resetFilter}
          triggerReset={triggerReset}
          state={state}
        />
      </AccordionDetails>
    </Accordion>
  );
};
const OrgFilter = ({ onOrgChange, resetFilter, state, triggerReset }) => {
  const [dataOrganizations, setDataOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const { data } = await getAllOrganizations();
      setDataOrganizations(data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Accordion>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
          <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            <MdApartment />
          </IconContext.Provider>
          <Typography fontSize={16} noWrap={true}>
            Organisations
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Spinner />
        ) : (
          <CustomizedHook
            options={dataOrganizations}
            label="Choose organization"
            onChange={onOrgChange}
            resetFilter={resetFilter}
            triggerReset={triggerReset}
            state={state}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const CountryFilter = ({ onCountryChange, options, selectedCountries, chipTitle, resetFilter, triggerReset }) => {
  return (
    <Accordion>
      <AccordionSummary aria-controls="panel-top-content" id="panel-top-header">
        <div className="d-flex align-items-center gap-3 justify-content-center">
        <IconContext.Provider
            value={{ color: '#2770F1', className: 'info-icon', size: 25 }}
          >
            <MdLanguage />
          </IconContext.Provider>
          <Typography fontSize={16} noWrap={true}>
            {chipTitle}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <CustomizedHook
          options={Array.isArray(options) ? options : []}
          label="Choose country"
          onChange={onCountryChange}
          resetFilter={resetFilter}
          triggerReset={triggerReset}
          state={selectedCountries}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const ExpertPageFilter = ({
  onCountryChange,
  onTechChange,
  onHIndexesChange,
  onOrgChange,
  onFilterChange,
  hIndexes,
  onTriggerFilter,
  onSearch,
  state,
}) => {
  const [countryOptions, setCountryOptions] = useState([]);
  // const countryOptions = ['Anh', 'Ba Lan', 'Belarus', 'Brazil', 'Bỉ', 'Bồ Đào Nha', 'Canada', 'Chile', 'Các Tiểu Vương quốc Ả Rập Thống nhất', 'Cộng Hòa Séc', 'Estonia', 'Hungary', 'Hà Lan', 'Hàn Quốc', 'Hồng Kông', 'Iceland', 'Ireland', 'Kazakhstan', 'Kuwait', 'Luxembourg', 'Malaysia', 'Mexico', 'Mỹ', 'Na Uy', 'New Zealand', 'Nga', 'Nhật Bản', 'Pakistan', 'Papua New Guinea', 'Pháp', 'Phần Lan', 'Saudi Arabia', 'Scotland', 'Singapore', 'Slovakia', 'Slovenia', 'Thái Lan', 'Thổ Nhĩ Kỳ', 'Thụy Sĩ', 'Thụy Điển', 'Trung Quốc', 'Tây Ban Nha', 'Việt Nam', 'Áo', 'Úc', 'Ý', 'Đan Mạch', 'Đài Loan', 'Đức', 'Ấn Độ'];
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [triggerReset, setTriggerReset] = useState(false);
  // Use a seperate object property for each sector keyword management
  const [allSectorKeyword, setAllSectorKeyword] = useState({});
  const isSmaller = useMediaQuery({ maxWidth: 1040 });

  const handleApplyFilter = () => {
    onFilterChange(true);
    onTriggerFilter();
    onSearch && onSearch();
    setTriggerReset(false);
  };

  const handleResetFilter = () => {
    setResetFilter(!resetFilter);
    onFilterChange(true);
    onTriggerFilter();
    setTriggerReset(true);
  };

  // Whenever the selected keyword changes, aggregate all chosen keywords to 1 array for the filter
  useEffect(() => {
    const newRelatedTech = Object.keys(allSectorKeyword)
      .map((key) => allSectorKeyword[key])
      .flat();
    onTechChange(newRelatedTech);
  }, [allSectorKeyword]);
      
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getAllCountries();
      setCountryOptions(data.filter((item) => item !== null));
    }
    fetchData();
  }, []);

  return (
    <div className="filter">
      {isSmaller ? (
        <ThemeProvider theme={accordionTheme}>
          <Accordion>
            <FilterAccordionSummary>
              <FilterHeader />
            </FilterAccordionSummary>
            <AccordionDetails>
              <div className="filter-main">
                <ThemeProvider theme={accordionTheme}>
                  <SectorFilter
                    relatedTech={allSectorKeyword}
                    onTechChange={setAllSectorKeyword}
                    resetFilter={resetFilter}
                    state={state?.relatedTech}
                    triggerReset={triggerReset}
                    filterTitle={'Sectors'}
                    icon={<MdApi />}
                  />
                  <OrgFilter
                    onOrgChange={onOrgChange}
                    resetFilter={resetFilter}
                    state={state?.org}
                    triggerReset={triggerReset}
                  />
                  <HIndexFilter
                    onHIndexesChange={onHIndexesChange}
                    hIndexes={hIndexes}
                    resetFilter={resetFilter}
                    state={state?.hIndexes}
                    triggerReset={triggerReset}
                  />
                  <CountryFilter
                    onCountryChange={onCountryChange}
                    options={countryOptions}
                    selectedCountries={selectedCountries}
                    chipTitle="Country"
                    resetFilter={resetFilter}
                    triggerReset={triggerReset}
                  />
                </ThemeProvider>
              </div>
              <div className="filter-btn-container">
                <ThemeProvider theme={theme}>
                  <Button
                    color="primary"
                    variant="contained"
                    endIcon={<MdFilterList />}
                    fullWidth
                    style={{ marginTop: 10, fontSize: 12, fontWeight: 600 }}
                    onClick={handleApplyFilter}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<MdFilterList />}
                    fullWidth
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      fontWeight: 600,
                      background: '#ffffff',
                      color: '#3D56B2',
                      borderColor: '#3D56B2',
                    }}
                    onClick={handleResetFilter}
                  >
                    Reset Filter
                  </Button>
                </ThemeProvider>
              </div>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      ) : (
        <>
          <FilterHeader />
          <div className="filter-main">
            <ThemeProvider theme={accordionTheme}>
              <SectorFilter
                relatedTech={allSectorKeyword}
                onTechChange={setAllSectorKeyword}
                resetFilter={resetFilter}
                state={state?.relatedTech}
                triggerReset={triggerReset}
                filterTitle={'Sectors'}
                icon={<MdApi />}
              />
              <OrgFilter
                onOrgChange={onOrgChange}
                resetFilter={resetFilter}
                state={state?.org}
                triggerReset={triggerReset}
              />
              <HIndexFilter
                onHIndexesChange={onHIndexesChange}
                hIndexes={hIndexes}
                resetFilter={resetFilter}
                state={state?.hIndexes}
                triggerReset={triggerReset}
              />
              <CountryFilter
                onCountryChange={onCountryChange}
                options={countryOptions}
                selectedCountries={selectedCountries}
                chipTitle="Country"
                resetFilter={resetFilter}
                triggerReset={triggerReset}
              />
            </ThemeProvider>
          </div>
          <div className="filter-btn-container">
            <ThemeProvider theme={theme}>
              <Button
                color="primary"
                variant="contained"
                endIcon={<MdFilterList />}
                fullWidth
                style={{ marginTop: 10, fontSize: 12, fontWeight: 600 }}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
              <Button
                variant="contained"
                endIcon={<MdFilterList />}
                fullWidth
                style={{
                  marginTop: 10,
                  fontSize: 12,
                  fontWeight: 600,
                  background: '#ffffff',
                  color: '#3D56B2',
                  borderColor: '#3D56B2',
                }}
                onClick={handleResetFilter}
              >
                Reset Filter
              </Button>
            </ThemeProvider>
          </div>
        </>
      )}
    </div>
  );
};

export default ExpertPageFilter;
