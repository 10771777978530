import React from 'react';
import Top from '../../components/organisation/Top';
import Sidebar from '../../components/organisation/Sidebar';
import ExpertPageFilter from '../../components/expert/ExpertPageFilter';
import { Collapse, ThemeProvider } from '@mui/material';
import ExpertContent from '../../components/expert/ExpertContent';
import theme from '../../commons/store/filterThemeProvider';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const ExpertPage = () => {
  const [isOpenFilter, setIsOpenFilter] = React.useState(true);
  const [relatedTech, setRelatedTech] = React.useState([]);
  const [org, setOrg] = React.useState([]);
  const [hIndexes, setHIndexes] = React.useState([]);
  const [isApplyFilter, setApplyFilter] = React.useState(false);
  const [handleTriggerFilter, setTriggerFilter] = React.useState(false);
  const [name, setName] = React.useState('');
  const [state, setState] = React.useState(useLocation().state);
  const isSmaller = useMediaQuery({ maxWidth: 1040 });
  const [selectedCountries, setSelectedCountries] = React.useState([]);

  React.useEffect(() => {
    if (state) {
      setHIndexes(state.hIndexes);
    }
  }, []);

  return (
    <>
      <Top 
        onNameChange={setName} 
      />
      <div className="organisation-content">
        <Sidebar isOpen={isOpenFilter} onSelect={setIsOpenFilter} />

        {isSmaller ? (
          <ExpertPageFilter
            onTechChange={setRelatedTech}
            onHIndexesChange={setHIndexes}
            onFilterChange={setApplyFilter}
            onOrgChange={setOrg}
            onCountryChange={setSelectedCountries}
            hIndexes={hIndexes}
            onTriggerFilter={() => {
              setTriggerFilter(!handleTriggerFilter);
              setState(null);
            }}
            state={state}
          />
        ) : (
          <ThemeProvider theme={theme}>
            <Collapse in={isOpenFilter} orientation="horizontal">
              <ExpertPageFilter
                onTechChange={setRelatedTech}
                onHIndexesChange={setHIndexes}
                onFilterChange={setApplyFilter}
                onOrgChange={setOrg}
                onCountryChange={setSelectedCountries}
                hIndexes={hIndexes}
                onTriggerFilter={() => {
                  setTriggerFilter(!handleTriggerFilter);
                  setState(null);
                }}
                state={state}
              />
            </Collapse>
          </ThemeProvider>
        )}

        <ExpertContent
          name={name}
          relatedTech={relatedTech}
          hIndexes={hIndexes}
          org={org}
          isApplyFilter={isApplyFilter}
          handleTriggerFilter={handleTriggerFilter}
          state={state}
          countries={selectedCountries}
        />
      </div>
    </>
  );
};

export default ExpertPage;
