import '../../pages/organisation/OrganisationPage.scss';
import { useState, useEffect, useContext } from 'react';
import { getSearchExpert } from '../../commons/apis/services/expert';
import Spinner from '../Spinner';
import Pagination from '@mui/material/Pagination';
import { ListExpertise } from '../organisation/OrganisationContent';
import './ExpertContent.scss';
import { Tooltip } from '@mui/material';

import SearchContext from '../../commons/store/search-context';

const ExpertContent = (props) => {
  const [sortBy, setSortBy] = useState('citations');
  const [isSortByDesc, setSortByDesc] = useState(true);
  const [loadingExperts, setLoadingExperts] = useState(false);
  const [expertData, setExpertData] = useState([]);
  const [page, setPage] = useState(1);
  const [articleNumber, setArticleNumber] = useState(0);
  const [relatedTech, setRelatedTech] = useState(0);
  const searchContext = useContext(SearchContext);

  const fetchData = async () => {
    try {
      setLoadingExperts(true);
      const { data: dataObject } = await getSearchExpert({
        name: searchContext.name,
        size: 10,
        page: page,
        sortBy: sortBy,
        sortByDesc: isSortByDesc,
        relatedTechs: props.state ? props.state.relatedTech : props.relatedTech,
        hIndexs: props.state ? props.state.hIndexes : props.hIndexes,
        relatedOrgs: props.state ? props.state.org : props.org,
        country: props.countries, //this is an array
      });
      setExpertData(dataObject.data.expert_data);
      setArticleNumber(dataObject.count);
      setRelatedTech(dataObject.data.count_tech_related);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExperts(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    sortBy,
    isSortByDesc,
    props.handleTriggerFilter,
    searchContext.name,
  ]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleOnChangeSortType = () => {
    setSortByDesc(!isSortByDesc);
  };

  const renderListItemExperts = () =>
    expertData?.map((item) => (
      <div
        key={item._id}
        id="item-organizations"
        className="form-control d-flex flex-row align-items-start w-100 flex-grow-1 mb-4"
      >
        <div className="infor d-flex flex-row align-items-between justify-content-between w-100">
          <div className="d-flex flex-row w-100 mb-mobile infor-header">
            <a href={'expert/' + item._id} className="img shadow rounded">
              <img
                onError={(e) => {
                  e.currentTarget.src = '/images/avatar/default.jpg';
                }}
                className="rounded w-100"
                src={item.avatar_url}
                alt={`${item.name}'s avatar`}
              />
            </a>
            <div className="d-flex flex-column align-items-start justify-content-start ms-4">
              <h3 className="mb-4">
                <a href={'expert/' + item._id} className="name">
                  {item.name}
                </a>
              </h3>
              <p className="org_des">
                {item.about &&
                  item.about
                    .split('.')
                    .map((e) => <p className="label-text">{e.trim()}</p>)}
              </p>
              <div className="d-flex flex-grow-1 flex-wrap">
                <ListExpertise expertise_name={item.expertise_names} />
              </div>
              <div
                style={{
                  color: '#666',
                  fontSize: '14px',
                  marginTop: '24px',
                  fontWeight: 500,
                }}
              >
                <p>SIMILAR EXPERTS</p>
                <div className="d-flex gap-3 flex-wrap">
                  {item.similar_experts.map((expert) => (
                    <Tooltip
                      key={expert._id}
                      title={<span className="h6">{expert.name}</span>}
                      placement="top"
                      arrow
                    >
                      <a href={`/expert/${expert._id}`}>
                        <img
                          className="similar-expert-avatar"
                          src={expert.avatar_url}
                          alt="similar expert avatar"
                        />
                      </a>
                    </Tooltip>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="note d-flex flex-column border-start">
            <div className="detail-info-2-cols">
              <div className="d-flex align-items-center">
                <span className="material-icons me-2 icon-note">apartment</span>
                <span className="me-2 text-title" style={{ width: '100px' }}>
                  Organisation
                </span>
              </div>
              {item.organisation_name ? (
                <label
                  className="text-note"
                  style={{ textTransform: 'capitalize' }}
                >
                  {item.organisation_name}
                </label>
              ) : (
                <label className="text-note">No data</label>
              )}
              <div className="d-flex">
                <span className="material-icons me-2 icon-note">
                  maps_home_work
                </span>
                <span className="me-2 text-title" style={{ width: '60px' }}>
                  I10-Index
                </span>
              </div>
              {item.i10_index ? (
                <label className="text-note">{item.i10_index}</label>
              ) : (
                <label className="text-note">No data</label>
              )}
              <div className="d-flex">
                <span className="material-icons me-2 icon-note">
                  format_quote
                </span>
                <span className="me-2 text-title">Citations</span>
              </div>
              {item.citations ? (
                <label className="text-note">{item.citations}</label>
              ) : (
                <label className="text-note">No data</label>
              )}
              <div className="d-flex">
                <span className="material-icons me-2 icon-note">
                  leaderboard
                </span>
                <span className="me-2 text-title">H-Index</span>
              </div>
              {item.h_index ? (
                <label className="text-note">{item.h_index}</label>
              ) : (
                <label className="text-note">No data</label>
              )}
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="content h-100">
      <div className="d-flex flex-column w-100">
        <h3 className='mobile-h3'>Expert Search Results</h3>
        <div className="bar d-flex w-100 flex-row border justify-content-between align-items-center form-control p-0 mt-3">
          <button className="btn btn-light px-0 border-start-0 border-top-0 border-bottom-0">
            <span className="material-icons">chevron_left</span>
          </button>
          <div className="related d-flex flex-row flex-grow-1 py-3 justify-content-start ps-4">
            <div className="d-flex flex-column justify-content-center align-items-center border-end">
              <h6>Experts Results</h6>
              <label>{articleNumber}</label>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h6 style={{ color: '#42BBFF' }}>Related Technologies</h6>
              <label>{relatedTech}</label>
            </div>
          </div>
          <button className="btn btn-light px-0 border-end-0 border-top-0 border-bottom-0">
            <span className="material-icons">chevron_right</span>
          </button>
        </div>
        <div className="sort d-flex flex-row align-items-center my-4">
          <h5>Sort by</h5>
          <select
            className="border-0 form-select"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="citations">Citations</option>
            <option value="h_index">h-index</option>
            <option value="i10_index">i10-index</option>
          </select>
          <button
            className="btn btn-light d-flex align-items-center border-0 p-0"
            title="Sort"
            onClick={handleOnChangeSortType}
          >
            <span className="material-icons">unfold_more</span>
          </button>
        </div>
        <div className="w-100">
          {loadingExperts ? <Spinner /> : renderListItemExperts()}
        </div>
        <div className="d-flex justify-content-center">
          <Pagination
            count={Math.ceil(articleNumber / 10)}
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
};

export default ExpertContent;
