import { httpService } from '../services';
import { API } from '../endPoint';

const getRelatedExpert = async ({
  user_id,
  page,
  limit,
}) => {
  let url = API.GET_PAPER_RELATED_EXPERTS;
  if (user_id) {
    url += `user_id=${encodeURIComponent(`${user_id}`)}&`;
  }

  if (page) {
    url += `page=${encodeURIComponent(`${page}`)}&`;
  }

  if (limit) {
    url += `limit=${encodeURIComponent(`${limit}`)}`;
  }

  url = url.replace(/[?&]$/, '');

  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getAllCountries = async () => {
  const url = API.GET_ALL_EXPERT_CONTRIES;
  const response = await httpService.get(url);
  return response;
};

const getSearchExpert = async ({
  name,
  sortBy,
  sortByDesc,
  size,
  page,
  relatedOrgs,
  hIndexs,
  relatedTechs,
  country, //this is an array
}) => {
  let url = API.GET_SEARCH_EXPERTS;
  if (name) {
    url += `name=${encodeURIComponent(`${name}`)}&`;
  }

  if (sortBy) {
    const sortDir = sortByDesc ? 'desc' : 'asc';
    url += `sort_by=${encodeURIComponent(`${sortBy}`)}-${sortDir}&`;
  }
  if (size) {
    url += `size=${encodeURIComponent(`${size}`)}&`;
  }
  if (page) {
    url += `page=${encodeURIComponent(`${page}`)}&`;
  }
  if (country && country.length > 0) {
    country.forEach((item) => {
      url += `country=${encodeURIComponent(item)}&`;
    });
  }

  if (hIndexs && hIndexs.length > 0) {
    hIndexs.forEach((item) => {
      url += `filter_h_index=${item}&`;
    });
  }
  if (relatedTechs && relatedTechs.length > 0) {
    relatedTechs.forEach((item) => {
      url += `expertise_names=${encodeURIComponent(item)}&`;
    });
  }

  if (relatedOrgs && relatedOrgs.length > 0) {
    relatedOrgs.forEach((item) => {
      url += `organisation_names=${encodeURIComponent(item)}&`;
    });
  }

  url = url.replace(/[?&]$/, '');

  const response = await httpService.post(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getExpertStatistic = async (id) => {
  let url = `${API.GET_EXPERTS_STATISTIC}expert_id=${id}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getExpertById = async (id) => {
  let url = `${API.GET_EXPERTS_BY_ID}expert_id=${id}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getSimilarExpert = async (id) => {
  let url = `${API.GET_SIMILAR_EXPERT}id=${id}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getTopExperts = async (count) => {
  let url = `${API.GET_TOP_EXPERTS}top_k=${count}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getAllLocations = async () => {
  let url = API.GET_ALL_LOCATIONS;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getAllLanguage = async () => {
  let url = API.GET_ALL_LANGUAGE;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getAllOccupations = async () => {
  let url = API.GET_ALL_OCCUPATIONS;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response;
};

const getListPaper = async (startYear, endYear) => {
  let url = API.GET_LIST_PAPERS;
  if (startYear) url += `?start_year=${startYear}`;
  if (endYear) url += `?end_year=${endYear}`;
  url = url.replace(/[?&]$/, '');
  const response = await httpService.get(url);

  if (response.code !== 200) {
    return null;
  }
  return response.data;
};

export {
  getRelatedExpert,
  getSearchExpert,
  getExpertStatistic,
  getExpertById,
  getSimilarExpert,
  getTopExperts,
  getAllLocations,
  getAllLanguage,
  getAllOccupations,
  getListPaper,
  getAllCountries,
};
